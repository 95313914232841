@tailwind base;
@tailwind components;
@tailwind utilities;

body {
 margin: 0;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

code {
 font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html {
 background-color: #242424;
 scroll-behavior: smooth;
}

.animateButton {
 text-decoration: none;
 background-image: linear-gradient(#fcfcfc, #fcfcfc);
 background-size: 0% 0.1em;
 background-position-y: 100%;
 background-position-x: 0%;
 background-repeat: no-repeat;
 transition: background-size 0.4s ease-in-out;
}
.animateButton:hover,
.animateButton:focus,
.animateButton:active {
 background-size: 100% 0.1em;
 cursor: pointer;
}
