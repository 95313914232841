.App {
 text-align: center;
}

.App-logo {
 height: 40vmin;
 pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
 .App-logo {
  animation: App-logo-spin infinite 20s linear;
 }
}

.App-header {
 background-color: #282c34;
 min-height: 100vh;
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 font-size: calc(10px + 2vmin);
 color: white;
}

.App-link {
 color: #61dafb;
}

@keyframes App-logo-spin {
 from {
  transform: rotate(0deg);
 }
 to {
  transform: rotate(360deg);
 }
}

.card {
 margin: 20px;
 border-radius: 20px;
 padding: 20px;
 box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card img {
 border-radius: 5px 5px 0 0;
}

.card:hover {
 box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.custom-fade-in {
 transition: opacity 2s ease-in-out; /* Adjust the duration as needed */
}
